import React, { useEffect, useState, useCallback } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import brush from '~/assets/icons/brush.svg';
import edit from '~/assets/icons/edit.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import TopLoader from '~/assets/loading.gif';
import { Container, Extras, Heart, Title } from '~/components/Container';
import { Row } from '~/pages/Dashboard/styles';
import Converter from '~/pages/Units/view/converter';
import Convertions from '~/pages/Units/view/convertions';
import DelegatesUsers from '~/pages/Units/view/delegatesusers';
import Followers from '~/pages/Units/view/followers';
import Medias from '~/pages/Units/view/medias';
import StatusBlock from '~/pages/Units/view/status';
import api from '~/services/api';
import { Editor } from './styles';
import { $ } from '../../styles/global';
import Tour from 'reactour';
import { toast } from 'react-toastify';
import { updateTutorialEnd } from 'store/modules/tutor/actions';
import history from '~/services/history';

export default function EditSubject({ match: { params: { id: postId } } }) {
  
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state => state.user.profile);
  const tutor = useSelector(state => state.tutorial);
  const dispatch = useDispatch();
  //states
  const [topLoader,setTopLoader] = useState(true);
  const [parentId,setParentId] = useState({});
  const [post, setPost] = useState({});
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [receive_emails, setReceive_emails] = useState(false);
  const [updateConvert,setUpdateConvert] = useState(true);







  const [project,setProject] = useState({});
  useEffect(()=>{
    async function getInitState(){
      const responseProjectId = await api.get(`units/${postId}`);
      const projectId = responseProjectId.data.unit.subject.area.project_id;
      try {
        const responseParent = await api.get(`projects/${projectId}`);
        setProject(responseParent.data);
      } catch(err){
        history.push(`/dashboard`);
      }
    }
    if(postId) getInitState();
  },[postId,history]);







  useEffect(() => {
    api.get(`units/${postId}`).then(response => {
      setParentId(response.data.unit.subject.id);
      setPost(response.data.unit);
    });
    setTimeout(() => {
      setTopLoader(false);
    }, 400);
    
  },[postId]);
  // UPDATE CONVERTION
  useEffect(() => {
    setTimeout(() => {
      if (profile?.role.level === 5) {
        api.get('users/settings').then(response => {
          if (response.data.first_access === true) {
            setIsTourOpen(true);
            setReceive_emails(response.data.receive_emails)
          }
        })
      }
    }, 3000/10);
  
  }, [topLoader,profile,tutor]);  

  function handleUpdateConvertion(){
    setUpdateConvert(!updateConvert);
  }

  const handleEndTuto = useCallback( async () => {
    try {
      const data = {
        first_access: false,
        receive_emails: receive_emails
      }
        await api.post(`users/settings`,data);
        toast.success('Você finalizou o tutorial. Mas caso precise de ajuda, acesse o menu > tutorial'); 
        setIsTourOpen(false);
        const first_access = '';
        dispatch(updateTutorialEnd(first_access));
      } catch (error) {
        toast.error('Opss, ocorreu um erro. Tente novamente');
      }
  }, [receive_emails,dispatch]);

  const steps = [
    {
      selector: ".first-step",
      position: 'top',
      content:
        <div>
          <h2>Editor de unidades</h2>
          <p>Estamos quase lá! Clique em "Ir para o editor" para começar a construir uma unidade.</p>
          {/* <JumpTuto className=".jump-tour" onClick={handleEndTuto} >Finalizar tour</JumpTuto> */}
        </div>
    }
  ]

  return (
    <>
      {(topLoader||!project.name)?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </Container>
      ):(
          <Container className={!post.available && `unavailable`}>
              <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                accentColor={$.colorA}
                onBeforeClose={handleEndTuto}
              />
          <Link to={`/subjects/${parentId}/units`}>
            <img src={leftArrow} alt={config.LANG['Backward']} />
            {config.LANG['Units']}
          </Link>
          <Heart>
            <div>
              <Title>{post.name}</Title>
            </div>
            <Followers postId={postId} />
          </Heart>
          {(profile&&profile.role.level<=2)&&(<>
            <Row>
              <Extras>
                <Link to={`/units/${postId}/extras`}>
                  <img src={brush} alt={config.LANG['Personalize']} />
                  {config.LANG['Personalize']}
                </Link>
              </Extras>
            </Row>
          </>)}
          <Row>
              <div className="first-step">
                <Editor>
                    <Link to={`/units/${postId}/create`}>
                      <img src={edit} alt="" />
                      {config.LANG['Go to Editor']}
                    </Link>
                </Editor>
              </div>
            <Converter
                postId={postId}
                callback={handleUpdateConvertion}
                topLoader={topLoader}
            />
          </Row>
          <Row>
            <Convertions postId={postId} post={post.name} update={updateConvert} />
            <Medias postId={postId} update={updateConvert} />
          </Row>
          <Row>
            <StatusBlock postId={postId} />
          </Row>
          {(profile&&profile.role.level<=2)&&(<>
            <DelegatesUsers postId={postId} />
          </>)}
        </Container>
      )}
    </>
  );
}