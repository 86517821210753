import React, {useState,useEffect,useMemo,useCallback} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import history from '~/services/history';

import api from '~/services/api';

import Timer from '~/util/Timer';

import {Container,Canvas,Header,Splitter,Preview,Loading} from '~/components/Container';
import Device from '~/components/Device';

import Multiple from '~/pages/Units/editor/multiple';
import Reload from '~/pages/Units/editor/reload';

import AddInteraction from '~/pages/Units/editor/popup/addinteraction';
import EditInteraction from '~/pages/Units/editor/popup/editinteraction';
import DeleteInteraction from '~/pages/Units/editor/popup/deleteinteractions';

// import EditMathType from '~/pages/Units/editor/popup/editmathtype';
// import MathTypeWindow from '~/pages/Units/editor/popup/mathtypewindow';

import Backups from '~/pages/Units/editor/popup/backups';
import Share from '~/pages/Units/editor/popup/share';
import WordCount from '~/pages/Units/editor/popup/wordcount';
import Comments from '~/pages/Units/editor/popup/comments';

import AddToolTip from '~/pages/Units/editor/popup/addtooltip';
import EditToolTip from '~/pages/Units/editor/popup/edittooltip';
import DeleteToolTip from '~/pages/Units/editor/popup/deletetooltip';

import logoWhite from '~/assets/logoWhite.svg';

import State from '~/pages/Units/editor/state';
import Save from '~/pages/Units/editor/save';
import Back from '~/pages/Units/editor/back';
import {
  initGenerator,
  initialContentGenerator,
  style,
  image,
  formatsGenerator,handleStyles,
  plugins,
  menuGenerator,
} from '~/pages/Units/editor/config';
import {Sanitize} from '~/pages/Units/editor/sanitize';
import { $ } from '../../styles/global';
import Tour from 'reactour';
import { disableBodyScroll } from "body-scroll-lock";
import * as TutorStyle from '../../styles/tutorial/tutorStyles';
import { toast } from 'react-toastify';
import { MdSlowMotionVideo } from 'react-icons/md';
import { updateTutorialEnd } from 'store/modules/tutor/actions';

var isCommentsSidebarOpen = false;

// const contentTimer = new Timer();
const ctrlZTimer = new Timer();

export default function UnitEditor({match:{params:{id:postId}}}) { // https://www.tiny.cloud/docs/demo/full-featured/
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state => state.user.profile);

  const [receive_emails, setReceive_emails] = useState(false);
  // START =====
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [iframe,setIframe] = useState(false);
  const [preview,setPreview] = useState(false);
  const [loaded,setLoaded] = useState(false);
  const [first,setFirst] = useState(true);
  const [post,setPost] = useState({});
  const [stage,setStage] = useState(null);
  const [interactions,setInteractions] = useState([]);
  const [template,setTemplate] = useState(false);
  const [styles,setStyles] = useState(false);
  const dispatch = useDispatch();








  const [project,setProject] = useState({});
  useEffect(()=>{
    async function getInitState(){
      const responseProjectId = await api.get(`units/${postId}`);
      const projectId = responseProjectId.data.unit.subject.area.project_id;
      try {
        const responseParent = await api.get(`projects/${projectId}`);
        setProject(responseParent.data);
      } catch(err){
        history.push(`/dashboard`);
      }
    }
    if(postId) getInitState();
  },[postId,history]);












  useEffect(()=>{
    GetInitState();
    async function GetInitState(){
      setLoaded(false);
      
      // const responseProjectId = await api.get(`units/${postId}`);
      // const projectId = responseProjectId.data.subject.area.project_id

      const procedureCurrentIdData = await api.get(`states/units/${postId}`);
      const procedureCurrentId = procedureCurrentIdData.data.list[0].procedure_project_id;
     
      const postPromise = api.get(`units/${postId}`);
      const templatePromise = api.get(`rules/units/${postId}`);
      const jobPromise = new Promise(async(resolve,reject) => {
        try {
          await api.get(`jobs/units/${postId}`,{
            params:{
              project_id: Number(procedureCurrentId)
            }
          });
          resolve(true);
        } catch(err){
          resolve(false);
        }
      });
      const editPromise = new Promise(async(resolve,reject) => {
        try {
          const response = await api.get(`edits/units/${postId}`);
          resolve(response);
        } catch(err){
          resolve(false);
        }
      });
      Promise.all([
        postPromise,
        templatePromise,
        jobPromise,
        editPromise,
      ]).then((values) => {
        const [
          {data:postData},
          {data:templateData},
          jobData,
          editData,
        ] = values;
        setPost(postData.unit);
        setTemplate(templateData);
        setJobOk(jobData);
        if(editData!==false){
          setContent(editData.data.html);
          setIframe(true);
          setPreview(editData.data.preview_url);
          setFirst(false);
        } else {
          setFirst(true);
        }
        const {path} = templateData;
        const interactionsPromise = api.get(`interactions/`,{params:{template:path,unit_id:postId}});
        const contentPromise = api.get(`styles/`,{params:{template:path}});
        Promise.all([
          interactionsPromise,
          contentPromise,
        ]).then((values) => {
          const [
            {data:interactionsData},
            {data:contentData}
          ] = values;
          setInteractions(interactionsData);
          setStyles(contentData);
          // setContent(contentData.content);
          setLoaded(true);
        });
      });
    }
  }, [postId]);
  

  useEffect(() => {
    setTimeout(() => {
      if (profile?.role.level === 5) {
        api.get('users/settings').then(response => {
          if (response.data.first_access === true) {
            setIsTourOpen(true);
            setReceive_emails(response.data.receive_emails)

          }
        })
      }
      //tutorial 
    }, 3000/10);
  
  }, [loaded, profile]); 
  // CONFIG =====
  const initialContent = useMemo(()=>initialContentGenerator(config),[config]);
  const [content,setContent] = useState(initialContent);
  useEffect(()=>{
    if(styles&&first) GetTemplateFirstContentTranslation();
    function GetTemplateFirstContentTranslation(){
      const result = handleStyles(config,styles);
      setContent(result.content);
    }
  },[config,postId,styles,first]);
  const init = useMemo(()=>initGenerator(config),[config]);
  const formats = useMemo(()=>formatsGenerator(config,styles),[config,styles]);
  const menu = useMemo(()=>menuGenerator(config,profile,template),[config,profile,template]);
  // UPDATE CONTENT ON EDITOR =====
  const handleEditorChange = useCallback((editorValue,editor)=>{
    setContent(editorValue);
  },[]);
  const setup = {
    setup: (editor) => {
      setStage(editor);
      editor.ui.registry.addMenuItem('share', {
        icon: 'new-tab',
        text: config.LANG['Preview Link'],
        onAction: function () {
          handleOpenShare();
        }
      });
      editor.ui.registry.addMenuItem('editor', {
        icon: 'edit-block', // spell-check
        text: config.LANG['Editor'],
        onAction: function () {
          history.push(`/units/${postId}/create`);
        }
      });
      // editor.ui.registry.addMenuItem('revisor', {
      //   icon: 'spell-check',
      //   text: config.LANG['Spelling'],
      //   onAction: function () {
      //     history.push(`/units/${postId}/revise`);
      //   }
      // });
      // editor.ui.registry.addMenuItem('compare', {
      //   icon: 'settings',
      //   text: 'Comparação',
      //   onAction: function () {
      //     history.push(`/units/${postId}/compare`);
      //   }
      // });
      editor.ui.registry.addMenuItem('contador', {
        icon: 'character-count',
        text: config.LANG['Word Counter'],
        onAction: function () {
          handleOpenWordCount();
        }
      });
      editor.ui.registry.addMenuItem('backup', {
        icon: 'restore-draft',
        text: config.LANG['Backup of Editions'],
        onAction: function () {
          handleOpenBackup();
        }
      });
      editor.ui.registry.addButton('interaction', {
        icon: 'plus',
        text: config.LANG['Resources'],
        onAction: function () {
          setOpenAdd(true);
        },
        onSetup: function (buttonApi) {
          // NÃO DEIXA INSERIR EM LOCAIS DIFERENTES QUE O PARÁGRAFO SIMPLES
          var editorEventCallback = function (eventApi) {
            buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase()!=='p');
            for (let i in eventApi.parents) {
              buttonApi.setDisabled(eventApi.parents[i].nodeName.toLowerCase()!=='p');
            }
          };
          editor.on('NodeChange', editorEventCallback);
  
          /* onSetup should always return the unbind handlers */
          return function (buttonApi) {
            editor.off('NodeChange', editorEventCallback);
          };
        }
      });
      editor.ui.registry.addButton('edit-interaction', {
        icon: 'edit-block',
        text: config.LANG['Edit Interaction'],
        onAction: function () {
          handleOpenEdit();
          // handleEditInteraction(editor);
        }
      });
      editor.ui.registry.addButton('delete-interaction', {
        icon: 'remove',
        onAction: function () {
          handleOpenDelete();
        }
      });
      editor.ui.registry.addButton('copy-interaction', {
        icon: 'copy',
        onAction: function () {
          var element = editor.selection.getNode();
          editor.focus();
          editor.selection.select(element);
          editor.execCommand("Copy");
        }
      });
      editor.ui.registry.addButton('addcomment', {
        icon: 'comment-add',
        onAction: function () {
          handleAddComment();
        },
        onSetup: function (buttonApi) {
          // NÃO DEIXA INSERIR EM LOCAIS DIFERENTES QUE O PARÁGRAFO SIMPLES
          var editorEventCallback = function (eventApi) {
            buttonApi.setDisabled(
              eventApi.element.nodeName.toLowerCase()==='div'&&
              eventApi.element.getAttribute('data-interaction')==='true'
            );
            for (let i in eventApi.parents) {
              buttonApi.setDisabled(
                eventApi.parents[i].nodeName.toLowerCase()==='div'&&
                eventApi.element.getAttribute('data-interaction')==='true'
              );
            }
          };
          editor.on('NodeChange', editorEventCallback);
  
          /* onSetup should always return the unbind handlers */
          return function (buttonApi) {
            editor.off('NodeChange', editorEventCallback);
          };
        }
      });
      editor.ui.registry.addButton('add-tooltip', {
        icon: 'non-breaking',
        text: 'Tooltip',
        onAction: function () {
          handleAddToolTip();
        },
        onSetup: function (buttonApi) {
          // NÃO DEIXA INSERIR EM LOCAIS DIFERENTES QUE O PARÁGRAFO SIMPLES E COM SELEÇÕES DE DIVS
          var editorEventCallback = function (eventApi) {
            const selectedContext = editor.selection.getContent();
            if(!selectedContext||selectedContext===''||selectedContext.includes('<')||selectedContext.includes('>')){
              buttonApi.setDisabled(true);
            } else {
              buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase()!=='p');
              for (let i in eventApi.parents) {
                buttonApi.setDisabled(eventApi.parents[i].nodeName.toLowerCase()!=='p');
              }
            }
          };
          editor.on('NodeChange', editorEventCallback);
      
          /* onSetup should always return the unbind handlers */
          return function (buttonApi) {
            editor.off('NodeChange', editorEventCallback);
          };
        }
      });
      editor.ui.registry.addButton('edit-tooltip', {
        icon: 'edit-block',
        onAction: function () {
          handleOpenEditToolTip();
        }
      });
      editor.ui.registry.addButton('delete-tooltip', {
        icon: 'remove',
        onAction: function () {
          handleOpenDeleteToolTip();
        }
      });
      editor.ui.registry.addContextToolbar('edit-interaction-listener', {
        predicate: function (node) {
          return node.nodeName.toLowerCase() === 'div' && 
          node.getAttribute('data-interaction')==='true';
        },
        items: 'edit-interaction | copy-interaction | delete-interaction',
        position: 'node',
        scope: 'node'
      });
      editor.ui.registry.addContextToolbar('edit-tooltip-listener', {
        predicate: function (node) {
          return node.nodeName.toLowerCase() === 'span' && 
          node.getAttribute('class')==='tooltip';
        },
        items: 'edit-tooltip | delete-tooltip',
        position: 'node',
        scope: 'node'
      });
      // editor.ui.registry.addButton('edit-mathtype', {
      //   icon: 'edit-block',
      //   text: config.LANG['Edit'],
      //   onAction: function () {
      //     handleOpenMathType();
      //     // handleEditInteraction(editor);
      //   }
      // });
      // editor.ui.registry.addContextToolbar('edit-mathtype-listener', {
      //   predicate: function (node) {
      //     return node.nodeName.toLowerCase() === 'img' && 
      //     node.getAttribute('class')==='Wirisformula' && 
      //     node.getAttribute('data-mathml');
      //   },
      //   items: 'edit-mathtype',
      //   position: 'node',
      //   scope: 'node'
      // });
      // https://www.tiny.cloud/docs/ui-components/customsidebar/
      // https://www.tiny.cloud/docs-4x/advanced/creating-a-sidebar/
      // https://stackoverflow.com/questions/46825012/how-to-open-close-sidebar-in-tinymce
      editor.ui.registry.addSidebar('comments', {
        tooltip: config.LANG['Comments'],
        icon: 'comment',
        onSetup: function (api) {
          // console.log('Render panel', api.element());
          // https://www.tiny.cloud/docs/advanced/events/
          editor.on('NodeChange', function(e) {
            // console.log('NodeChange',e);
            // console.log('e.element',e.element);
            var element = editor.selection.getNode();
            var comments = element.getAttribute('data-comments');
            comments = (comments)?JSON.parse(comments):comments;
            (comments&&!e.selectionChange)&&openCommentsSidebar(editor);
            (!comments&&!e.selectionChange)&&closeCommentsSidebar(editor);
            renderComments();
            function renderComments(){
              api.element().innerHTML = `<div class="comments">
                <h1>${config.LANG['Comments']}:</h1>
                <ul>
                ${(comments)?
                comments.map((item,index)=>`<li>
                  ${item.comment}<br/>
                  <strong>${config.LANG['By']} ${item.name}</strong>
                  ${(item.users)?`<strong class="users">${item.users.reduce((total,next)=>{return total+'<span>'+next.text+'</span>'},'')}</strong>`:''}
                  <button class="delete-comment" type="button" data-index="${index}">${config.LANG['delete']}</button>
                </li>`):
                (`<li>${config.LANG['none']}...</li>`)}
                </ul>
              </div>`;
              var buttons = api.element().querySelectorAll('.delete-comment');
              Array.prototype.forEach.call(buttons, function(button, i){
                var index = Number(button.getAttribute('data-index'));
                button.onclick = function(){
                  comments.splice(index,1);
                  if(comments.length===0){
                    element.removeAttribute('data-comments');
                    element.removeAttribute('data-users');
                    comments = null;
                  } else {
                    element.setAttribute('data-comments', JSON.stringify(comments));
                  }

                  // const target = document.createElement("p");
                  // element.parentNode.insertBefore(target, element.nextSibling);
                  // console.log('editor',editor);

                  // var selection = editor.selection.getContent();
                  // console.log('selection',selection);
                  // editor.selection.setNode(element);

                  // editor.selection.select(element);

                  var selection = editor.selection.getContent();
                  editor.insertContent(selection);
                  renderComments();
                };
              });
            }
          });
        },
        onShow: function (api) {
          // console.log('Show panel', api.element())
          isCommentsSidebarOpen = true;
        },
        onHide: function (api) {
          // console.log('Hide panel', api.element());
          isCommentsSidebarOpen = false;
        }
      });
      editor.on(`change`,async function(args){
        // contentTimer.register(() =>{
          handleEditorChange(editor.getContent(),editor);
        // });
      });
      editor.on(`keydown`, async function(args){
        var element = editor.selection.getNode();

        // BLOCK JOB =====
        handleJobOk(args);

        // console.log('args',args);
        // console.log('args.key',args.key);

        // console.log('element',element);
        // console.log('selection',editor.selection);
        // console.log('selection.getContent',editor.selection.getContent());
        // console.log('selection.getSel',editor.selection.getSel());
        // console.log('selection.getSelectedBlocks',editor.selection.getSelectedBlocks());
        // console.log('selection.getRng',editor.selection.getRng());

        // SAVE UNDO
        // editor.focus();
        // editor.blur();
        ctrlZTimer.register(() =>{
          editor.undoManager.add();
        });
        // editor.undoManager.add();
        // editor.undoManager.beforeChange();

        // console.log('THOMAS ========== ',args.key);

        var selection = editor.selection.getContent();
        // INTERACTION SELECTION ==========================================
        if(selection.includes('data-interaction="true"')&&element.getAttribute('id')==='tinymce') {
          if(args.key==='Backspace'||args.key==='Delete'){
            // args.preventDefault();
            var selectedBlocks = editor.selection.getSelectedBlocks();
            for (let i in selectedBlocks) {
              var block = selectedBlocks[i];
              // block.parentNode.removeChild(block);
              if(block.dataset.interaction) {
                block.parentNode.removeChild(block);
              }
            }
            // toast.error('Não é possível deletar interações dessa forma. Clique na interação desejada e selecione o ícone para remover.');
          }
        }
        // TOPIC ONLY ==========================================
        else if(element.getAttribute('class')==='E-TOPICO'){
          // console.log(args.key);
          if(args.key==='Enter'){
            args.preventDefault();
            var paragraph = document.createElement('p');
            var text = document.createTextNode('\u00A0'); // &nbsp;
            paragraph.appendChild(text);
            element.parentNode.insertBefore(paragraph, element.nextSibling);
            editor.focus();
            editor.selection.select(paragraph);
          } else if(args.key==='Delete'){
            args.preventDefault();
          }
        }
        // INTERACTION ONLY ==========================================
        else if(element.getAttribute('data-interaction')==='true'){
          // console.log(args.key);
          if(args.key==='Enter'){
            args.preventDefault();
            var paragraphNew = document.createElement('p');
            var textNew = document.createTextNode('\u00A0'); // &nbsp;
            paragraphNew.appendChild(textNew);
            element.parentNode.insertBefore(paragraphNew, element);
            editor.focus();            
          } else if (args.key==='Backspace'||args.key==='Delete'){
            args.preventDefault();
            element.parentNode.removeChild(element);
            editor.focus();
          } else if (args.key==='c'){
            args.preventDefault();
            editor.selection.select(element);
            editor.execCommand("Copy");
          } else {
            args.preventDefault();
          }
        }
        // DELETE ANALISES 
        else if(args.key==='Delete'){
          // console.log(selection);
          // console.log(element);
          args.key = 'Backspace';
          args.code = 'Backspace';
          args.keyCode = 8;
          args.which = 8;
          // console.log('Saiu como Backspace');
          
          // args.preventDefault();
          // var newKeyDown = { // https://www.tiny.cloud/docs/api/tinymce.util/tinymce.util.eventdispatcher/
          //   ...args,
          //   key: 'Backspace',
          //   code: 'Backspace',
          //   keyCode: 8,
          //   which: 8
          // };
          // editor.fire("keydown",newKeyDown);

          // editor.fire("keydown", {
          //   bubbles : true,
          //   cancelable : true,
          //   char : "Q",
          //   key : "q",
          //   shiftKey : true,
          //   keyCode : 8
          // });


          // var backspaceEvnt = new KeyboardEvent('keydown', {bubbles : true, cancelable : true, key : "Backspace", char : "Backspace", shiftKey : true });
          // var backspaceEvnt = new KeyboardEvent("keydown", {
          //   bubbles : true,
          //   cancelable : true,
          //   char : "Q",
          //   key : "q",
          //   shiftKey : true,
          //   keyCode : 81
          // });
          // console.log(editor);
          // editor.fire(backspaceEvnt);
          // editor._eventDispatcher.bindings.keydown[8].func();
          // editor.selection.dom.doc.dispatchEvent(backspaceEvnt);
          // editor.dispatchEvent(backspaceEvnt);

          // var evt = editor.selection.dom.doc.createEvent("KeyboardEvent");
          // evt.initKeyEvent ("keypress", true, true, window,
          //                 0, 0, 0, 0,
          //                 8, 8); 
          // editor.selection.dom.doc.dispatchEvent(evt);


          // var elements = editor.selection.dom.doc.querySelectorAll('[data-interaction=true]');
          // Array.prototype.forEach.call(elements, function(el, i){
          //   console.log(el);
          //   el.classList.add('mceNonEditable');
          // });
          // console.log('passou');
          // args.initEvent();
        }
      });
    }, // https://www.tiny.cloud/docs/advanced/events/
    init_instance_callback : function(editor) {
      // console.log("Editor: " + editor.id + " is now initialized.");
      // console.log('editor.ui.registry.getAll(',editor.ui.registry.getAll());
      // for (var key in editor.ui.registry.getAll().buttons) {
      //   var button = editor.ui.registry.getAll().buttons[key];
      //   console.log(button);
      // }
    },
  };
  // JOBS =====
  const [jobOk,setJobOk] = useState(false);
  
  const openJob = useMemo(()=>{
    if(config.HIDE_JOB){
      if(profile&&profile.role.level<=3){} else {
        if(!jobOk) return false;
      }
    }
    return true;
  },[config,profile,jobOk]);
  
  const handleJobOk = useCallback((args)=>{
    if(!openJob) args.preventDefault();
  },[openJob]);

  const callbackJob = useCallback(async()=>{
    try {
      await api.get(`jobs/units/${postId}`);
      setJobOk(true);
    } catch(err){
      setJobOk(false);
      if(config.HIDE_JOB){
        if(profile&&profile.role.level<=3){} else {
          history.push(`/units/${postId}/view`);
        }
      }
    }
  },[postId,config,profile]);
  // SAVE AND RELOAD =====
  const [saveStatus,setSaveStatus] = useState(true);
  const handleReload = useCallback(async()=>{
    const backupPreview = `${preview}`;
    setPreview('');
    if(!preview){
      const response = await api.get(`edits/units/${postId}`);
      setPreview(response.data.preview_url);
    } else {
      setTimeout(() => {
        setPreview(backupPreview);
      }, 100);
    }
  },[postId,preview]);
  // ADD INTERACTION =====
  const [openAdd,setOpenAdd] = useState(false);
  const handleOpenAdd = useCallback(()=>setOpenAdd(!openAdd),[openAdd]);
  // EDIT INTERACTION =====
  const [openEdit,setOpenEdit] = useState(false);
  const handleOpenEdit = useCallback(()=>setOpenEdit(!openEdit),[openEdit]);
  // EDIT MATHTYPE =====
  const [openMathType,setOpenMathType] = useState(false);
  const handleOpenMathType = useCallback(()=>setOpenMathType(!openMathType),[openMathType]);
  // DELETE INTERACTION =====
  const [openDelete,setOpenDelete] = useState(false);
  const handleOpenDelete = useCallback(()=>setOpenDelete(!openDelete),[openDelete]);
  // BACKUPS =====
  const [openBackup,setOpenBackup] = useState(false);
  const handleOpenBackup = useCallback(()=>setOpenBackup(!openBackup),[openBackup]);
  // WORD COUNT =====
  const [openWordCount,setOpenWordCount] = useState(false);
  const handleOpenWordCount = useCallback(()=>setOpenWordCount(!openWordCount),[openWordCount]);
  // SHARE =====
  const [openShare,setOpenShare] = useState(false);
  const handleOpenShare = useCallback(()=>setOpenShare(!openShare),[openShare]);
  // COMMENTS =====
  const [addComment,setAddComment] = useState(false);
  const handleAddComment = useCallback(()=>setAddComment(!addComment),[addComment]);
  const openCommentsSidebar = useCallback((handler)=>{
    if(!isCommentsSidebarOpen) handler.execCommand('ToggleSidebar', false, 'comments'); // https://www.tiny.cloud/docs/advanced/editor-command-identifiers/
  });
  const closeCommentsSidebar = useCallback((handler)=>{
    if(isCommentsSidebarOpen) handler.execCommand('ToggleSidebar', false, 'comments'); // https://www.tiny.cloud/docs/advanced/editor-command-identifiers/
  });
  // ADD TOOLTIP =====
  const [addToolTip,setAddToolTip] = useState(false);
  const handleAddToolTip = useCallback(()=>setAddToolTip(!addToolTip),[addToolTip]);
  // EDIT TOOLTIP =====
  const [openEditToolTip,setOpenEditToolTip] = useState(false);
  const handleOpenEditToolTip = useCallback(()=>setOpenEditToolTip(!openEditToolTip),[openEditToolTip]);
  // DELETE TOOLTIP =====
  const [openDeleteToolTip,setOpenDeleteToolTip] = useState(false);
  const handleOpenDeleteToolTip = useCallback(()=>setOpenDeleteToolTip(!openDeleteToolTip),[openDeleteToolTip]);
  // NOT SAVED =====
  const [notSaved,setNotSaved] = useState(false);
  // DEVICE =====
  const [device,setDevice] = useState('0.5');
  useEffect(()=>{
    const viewEditorSize = localStorage.getItem('@RealizeSystem-viewEditorSize');
    if(viewEditorSize) setDevice(viewEditorSize);
  },[postId]);
  const handleDevice = useCallback((data)=>{
    setDevice(data);
    localStorage.setItem('@RealizeSystem-viewEditorSize',data);
  }, []);
  

  const handleEndTuto = useCallback( async () => {
    try {
      const data = {
        first_access: false,
        receive_emails: receive_emails
      }
        await api.post(`users/settings`,data);
        toast.success('Você finalizou o tutorial. Mas caso precise de ajuda, acesse o menu > tutorial'); 
        setIsTourOpen(false);
        const first_access = '';
        dispatch(updateTutorialEnd(first_access));
      } catch (error) {
        toast.error('Opss, ocorreu um erro. Tente novamente');
      }
  }, [receive_emails,dispatch]);


  const steps = [
    {
      selector: "",
      content:
        <div>
          <h2>Construindo unidades de ensino</h2>
          <p>Este é o editor de unidades Realize! Aqui você poderá construir suas
            unidades de maneira simples e rápida e poderá acompanhar o
            resultado final em tempo real.</p>
          {/* <JumpTuto onClick={handleEndTuto} className=".jump-tour">Finalizar tour</JumpTuto> */}
        </div>
    },
    {
      selector: 'div.tox-editor-container',
      content:
        <div>
          <h2>Área de construção</h2>
          <p>Nesta área você poderá inserir textos, recursos interativos, gráficos, tópicos, tabelas e muito mais!</p>
          {/* <JumpTuto onClick={handleEndTuto} className=".jump-tour">Finalizar tour</JumpTuto> */}
        </div>,
    },
    {
      selector: 'div.tox-sidebar-wrap',
      content:
        <div>
          <h2>Editor de texto</h2>
          <p>Vamos começar? Aqui você pode digitar seu conteúdo! Em seguida, clique na setinha abaixo para avançar.</p>
          {/* <JumpTuto onClick={handleEndTuto} className=".jump-tour">Finalizar tour</JumpTuto> */}
        </div>,
    },
    {
      selector: '.save',
      content:
        <div>
          <h2>Salvar alterações</h2>
          <p>Para visualizar as alterações realizadas, é necessário SALVAR a unidade.</p>
          <br/>
          <p>Clique em salvar para continuar.</p>
          {/* <JumpTuto onClick={handleEndTuto} className=".jump-tour">Finalizar tour</JumpTuto> */}
        </div>,
    },
    {
      selector: '.preview-container',
      content:
        <div>
          <h2>Preview</h2>
          <p>Agora você pode visualizar o resultado do seu trabalho!</p>
          <br />
          <p>Vamos continuar? Assita ao vídeo para mais informações </p>
          <TutorStyle.VideoContainer>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=hBiSrk0kadI">
              <MdSlowMotionVideo size={20}/>
              Assistir ao vídeo
            </a>
          </TutorStyle.VideoContainer>
          {/* <JumpTuto onClick={handleEndTuto} className=".jump-tour">Finalizar tour</JumpTuto> */}
        </div>,
    },

    // {
    //   selector: 'div.tox-toolbar-overlord div.tox-toolbar__primary div.tox-toolbar__group:last-child',
    //   content:
    //     <div>
    //       <h2>Recursos</h2>
    //       <p>Clique nos três pontinhos e em seguida em "+ Recursos" para adicionar Recursos Visuais e Interativos</p>
    //       <JumpTuto className=".jump-tour" type="submit">Finalizar tour</JumpTuto>
  
    //     </div>,
    // },
    // //modal aberto
    // {
    //   selector: 'div.sc-jwKygS.hLSUpM',
    //   action: () => {
    //     setOpenAdd(true);
    //   },
    //   position: 'top',
    //   content:
    //     <div>
    //       <h2>Adicionar recursos</h2>
    //       <p>Primeiro selecione o projeto que deseja trabalhar</p>
    //       <JumpTuto className=".jump-tour" type="submit">Finalizar tour</JumpTuto>
  
    //     </div>,
    // },
    // //modal fechado
    // {
    //   selector: '.tox-edit-area iframe.tox-edit-area__iframe:first-child:last-child',
    //   action: () => {
    //     // setInteractions(interactions[6])
    //   },
    //   content:
    //     <div>
    //       <h2>Onde o recurso é adicionado</h2>
    //       <p>Primeiro selecione o projeto que deseja trabalhar</p>
    //       <JumpTuto className=".jump-tour" type="submit">Finalizar tour</JumpTuto>
    //     </div>,
      
    // },
  ]

  return (
    <>
     
    <Loading fade={loaded}>
      <img src={logoWhite} alt="Logo da Realize enquanto a tela carrega"/>
      <h1>{config.LANG['Loading']}...</h1>
    </Loading>
    {(loaded&&project.name)&&(
        <>
            <Canvas saving={!saveStatus?true:false}>
              <Header>
                <div className="middleWord">
                  <div>
                  <Back postId={postId} notSaved={notSaved} />
        
                    <Save 
                      postId={postId}
                      editor={stage}
                      template={template}
                      content={content}
                      Sanitize={Sanitize}

                      saveStatus={saveStatus} setSaveStatus={setSaveStatus}
                      first={first} setFirst={setFirst}

                      setNotSaved={setNotSaved}

                      openJob={openJob}
                      setIframe={setIframe}
                      handleReload={handleReload}
                  />
                </div>
                  <State postId={postId} openJob={openJob} callbackJob={callbackJob} />
                </div>
                <div className="middleWord">
                  <Device device={device} callback={handleDevice} />
                  <Multiple postId={postId} post={post} handleReload={handleReload} />
                  <Reload iframe={iframe} handleReload={handleReload} />
                </div>
              </Header>
            <Splitter>
                <Container device={device} isEditor={true} className={!post.available&&`unavailable`}>
                  <Editor
                    initialValue={content}
                    init={{
                      ...init,
                      ...style,
                      ...formats,
                      ...image,
                      ...plugins,
                      ...menu,
                      ...setup
                    }}
                    // onEditorChange={handleEditorChange}
                  />
                  
                </Container>
                
              <Preview device={device}>
                {iframe ? (
                      <iframe title="preview-editor" className="preview-container" src={preview}></iframe>
                    ):(
                      <div className="preview-container">{config.LANG['Save to view your work']}.</div>
                    )}
                </Preview>
              </Splitter>
            </Canvas>
            <AddInteraction Open={openAdd}  Close={handleOpenAdd} editor={stage} interactions={interactions} />
            <EditInteraction Open={openEdit} Close={handleOpenEdit} editor={stage} interactions={interactions} />
            {/* <EditMathType Open={openMathType} Close={handleOpenMathType} editor={stage} /> */}
            {/* <MathTypeWindow Open={openMathType} Close={handleOpenMathType} editor={stage} /> */}
            <DeleteInteraction Open={openDelete} Close={handleOpenDelete} editor={stage} />
            <Backups Open={openBackup} Close={handleOpenBackup} editor={stage} postId={postId} />
            <Share Open={openShare} Close={handleOpenShare} postId={postId} />
            <WordCount Open={openWordCount} Close={handleOpenWordCount} editor={stage} InteractionsData={interactions} />
            <Comments Open={addComment} Close={handleAddComment} CallBack={openCommentsSidebar} postId={postId} editor={stage} />
            <AddToolTip Open={addToolTip} Close={handleAddToolTip} postId={postId} editor={stage} />
            <EditToolTip Open={openEditToolTip} Close={handleOpenEditToolTip} editor={stage} />
            <DeleteToolTip Open={openDeleteToolTip} Close={handleOpenDeleteToolTip} editor={stage} />
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
            accentColor={$.colorA}
            onAfterOpen={target => disableBodyScroll(target)}
            onBeforeClose={handleEndTuto}
          />
      </>
    )}
    </>
  );
}