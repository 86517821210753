import React, { useEffect, useState, useCallback } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import leftArrow from '~/assets/icons/leftArrow.svg';
import { Container, Row } from '~/pages/Dashboard/styles';
import Areas from '~/pages/Overview/components/areas';
import Convertions from '~/pages/Overview/components/convertions';
import Delegates from '~/pages/Overview/components/delegates';
import Followers from '~/pages/Overview/components/followers';
import Plan from '~/pages/Overview/components/plan';
import Status from '~/pages/Overview/components/status';
import Subjects from '~/pages/Overview/components/subjects';
import Units from '~/pages/Overview/components/units';
import api from '~/services/api';
import { $ } from '../../styles/global';
import TopLoader from '~/assets/loading.gif';
import Tour from 'reactour';
// import { JumpTuto} from '../Dashboard/styles';
import { disableBodyScroll } from 'body-scroll-lock';
import history from '~/services/history';
import { toast } from 'react-toastify';
import { updateTutorialEnd } from 'store/modules/tutor/actions';

export default function Dashboard({ match: { params: { id: parentId } } }) {
  const profile = useSelector(state=>state.user.profile);
  const config = useSelector(state=>state.config.value);
  const [parent, setParent] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const tutor = useSelector(state => state.tutorial);
  const [receive_emails, setReceive_emails] = useState(false);
  const dispatch = useDispatch();


  useEffect(()=>{
    async function GetInitState(){
      try {
        const response = await api.get(`projects/${parentId}`);
        setParent(response.data);
      } catch(err){
        history.push(`/dashboard`);
      }
    }
    GetInitState();
  }, [parentId]);
  
  useEffect(() => {
      setTimeout(() => {
        if (profile?.role.level === 5) {
          api.get('users/settings').then(response => {
            if (response.data.first_access === true) {
              setIsTourOpen(true);
              setReceive_emails(response.data.receive_emails)
            }
          })
        }
        //tutorial 
      }, 3000/10);
    
  }, [parent,profile,tutor]);

  const handleEndTuto = useCallback( async () => {
    try {
      const data = {
        first_access: false,
        receive_emails: receive_emails
      }
        await api.post(`users/settings`,data);
        toast.success('Você finalizou o tutorial. Mas caso precise de ajuda, acesse o menu > tutorial'); 
        setIsTourOpen(false);
        const first_access = '';
        dispatch(updateTutorialEnd(first_access));
      } catch (error) {
        toast.error('Opss, ocorreu um erro. Tente novamente');
      }
  }, [receive_emails,dispatch]);

  const steps = [
    {
      selector: ".firs-step",
      content:
        <div>
          <h2>Visão geral do projeto</h2>
          <p>Aqui você pode ter uma visão geral do projeto selecionado,
            onde ficarão cadastradas as áreas de conhecimento,
            as disciplinas e as unidades de ensino de cada projeto.</p>
            {/* <JumpTuto className=".jump-tour" onClick={handleEndTuto}>Finalizar tour</JumpTuto> */}
        </div>
    },
    {
      selector: '.second-step',
      content:
        <div>
          <h2>Unidades de ensino</h2>
          <p>Esta é a sua lista de unidades de ensino.</p>
          <br/>
          <p> Selecione uma unidade para começar a construir.</p>
          {/* <JumpTuto className=".jump-tour" onClick={handleEndTuto}>Finalizar tour</JumpTuto> */}
        </div>
      ,position: 'top'
    }
  ]

  return (
    <Container>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor={$.colorA}
        onAfterOpen={target => disableBodyScroll(target)}
        onBeforeClose={handleEndTuto}
      />
      <div>
        <Link className="linkBack" to="/dashboard">
          <img src={leftArrow} alt="Voltar"/>
          {config.LANG['Dashboard']}
        </Link>
        <h1 className="firs-step">{config.LANG['Overview']}</h1>
        <h2>{config.LANG['Project']}: {parent&&parent.name}</h2>
        {parent&&parent.name?(<>
          <Row>
            <Areas parentId={parentId} />
            <Subjects parentId={parentId} />

            <Units  parentId={parentId} />
          </Row>
          {/* <Row>
            <Delegates parentId={parentId} />
            <Followers parentId={parentId} />
          </Row> */}
          <Row>
            <Status parentId={parentId} />
          </Row>
          <Row>
            <Convertions parentId={parentId} />
            {/* <Plan /> */}
            <Followers parentId={parentId} />
          </Row>
        </>):(<p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>)}
      </div>
    </Container>
  );
}