import React,{useEffect,useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import api from '../../services/api';
import {Container,Row,NavContainer} from './styles';
import rightArrow from '~/assets/icons/rightArrow.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import HelloWorld from '~/pages/Dashboard/components/helloworld';
import Projects from '~/pages/Dashboard/components/projects';
import PlanConvertion from '~/pages/Dashboard/components/planConvertion';
import PlanUser from '~/pages/Dashboard/components/planUser';
import { toast } from 'react-toastify';
import ProjectSkalaton from '../skeleton/ProjectsDashboard';
import { $ } from '../../styles/global';
import Tour from 'reactour';
import { disableBodyScroll } from 'body-scroll-lock';
import { useCallback } from 'react';
import { updateTutorialEnd } from 'store/modules/tutor/actions';


export default function Dashboard() {
  const config = useSelector(state => state.config.value);
  const profile = useSelector(state=>state.user.profile);
  const tutor = useSelector(state=>state.tutorial)

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [topLoader, setTopLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState({
    list: [],
    paged: {}
  });
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [users,setUsers] = useState(0);
  const [convertions,setConvertions] = useState(0);
  const [loadSkeleton, setLoadSkeleton] = useState(true);
  const [receive_emails, setReceive_emails] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      Promise.all([
        api.get('projects', { params: { perPage: 1 } }),
        api.get('projects',{
          params: {
            perPage: 8,
            page,
          }
        }),
        api.get('users/count'),
        api.get('convertions/count'),
      ]).then(values => {
        setProjects(values[0].data);
        setPosts(values[1].data);
        setUsers(values[2].data.users);
        setConvertions(values[3].data.convertions);
      });
     
    } catch (error) {
      toast.error(`Ops, não foi possível carregar os dados`)
      console.error(error);
      setTopLoader(true);
    }
    setTimeout(() => {
      setTopLoader(false);
    }, 900);
  }, [page]);
  
  useEffect(() => {
    if (topLoader === false) {
      setTimeout(() => {
        if (profile?.role.level === 5) {
          api.get('users/settings').then(response => {
            if (response.data.first_access === true) {
              setIsTourOpen(true);
              setReceive_emails(response.data.receive_emails)
            }
          })
        }
        setLoadSkeleton(false)
      }, 1000);
    }
  }, [topLoader,profile,tutor]);

  function handlePage(i) {
    setPage(i);
  }

  const handleEndTuto = useCallback( async () => {
    try {
      const data = {
        first_access: false,
        receive_emails: receive_emails
      }
        await api.post(`users/settings`,data);
      toast.success('Você finalizou o tutorial. Mas caso precise de ajuda, acesse o menu > tutorial'); 
        setIsTourOpen(false);
        const first_access = '';
        dispatch(updateTutorialEnd(first_access));
      } catch (error) {
        toast.error('Opss, ocorreu um erro. Tente novamente');
      }
  }, [receive_emails,dispatch]);

  

  const steps = [
    {
      selector: "",
      content: () => (
          <div>
            <h2>Olá! Este é o tour guiado do sistema Realize</h2>
            <p>Aqui você vai conhecer as principais funcionalidades do sistema Realize!</p>
            <br/>
            <p>Avance para continuar ou feche este tutorial caso já conheça a plataforma</p>
            {/* <JumpTuto onClick={() => handleEndTuto('jump')} className=".jump-tour">Pular tour</JumpTuto> */}
          </div>
      )
    },
    {
      selector: '.projects-container',
      content: () => (
          <div>
            <h2>Selecionando projetos</h2>
            <p>Este é o seu painel de projetos.</p>
            <br/>
            <p>Selecione um projeto para continuar.</p>
            {/* <JumpTuto onClick={handleEndTuto} className=".jump-tour">Finalizar tour</JumpTuto> */}
          </div>
      )
    }
  ]

  return (
    <Container>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor={$.colorA}
        onAfterOpen={target => disableBodyScroll(target)}
        onBeforeClose={handleEndTuto}
      />
      <div>
        <h1>{config.LANG['Dashboard']}</h1>
        <Row>
          <HelloWorld
            projects={projects}
            topLoader={topLoader}/>
        </Row>
        <Row>
          <div className="projects-container">
            {loadSkeleton === true || posts.list[0] ===false ? 
              <ProjectSkalaton />
              :
              <Projects  topLoader={topLoader} posts={posts} />
            }
            <NavContainer>
                {posts.paged.pages>1&&(
                  <nav className="arrows-nav">
                    <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                      <img src={leftArrow} alt={config.LANG['Backward']} />
                    </button>
                    <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                      <img src={rightArrow} alt={config.LANG['Forward']} />
                    </button>
                  </nav>
                )}
            </NavContainer>
          </div>
        </Row>
        {(profile?.role.level<=2)&&(
          <Row>
            <PlanConvertion
              convertions={convertions}
              topLoader={topLoader} />
            <PlanUser
              topLoader={topLoader}
              users={users} />
          </Row>
        )}
      </div>
     
    </Container>
  );
}