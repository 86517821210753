import {lighten} from 'polished';

import api from '~/services/api';

import {$} from '~/styles/global';

import commentIcon from '~/assets/icons/comment.svg';

export function initGenerator(config){
  var language = 'en';
  if(config.LANGUAGE==='pt_br'){
    language = 'pt_BR';
  } else if(config.LANGUAGE==='es_es'){
    language = 'es';
  }
  return {
    language, // https://www.tiny.cloud/get-tiny/language-packages/
    branding: false,
    resize: false,
    height: '100%',
    // skin: "realize",
    // content_css : '../../tinymce/skins/content/realize/content.css',
  };
}

export function initialContentGenerator(config){
  var initialCode = `<div class="E-TOPICO">Introduction</div>
  <p>Hello!</p>`;
  if(config.LANGUAGE==='pt_br'){
    initialCode = `
      <div class="E-TOPICO">Introdução</div>
      <p>Olá aluno, tudo bem?</p>
      <p>...</p>
      <p>Bons estudos!</p>
      <div class="E-TOPICO">Tópico 1</div>
      <p>...</p>
      <div class="E-TOPICO">Referências Bibliográficas</div>
      <p>HONORATO, Gilson. <em>Conhecendo o Marketing</em>. Barueri: Manole, 2004.</p>
      <p>KOTLER, Philip; KELLER, Kevin. <em>Administra&ccedil;&atilde;o de Marketing.</em> 14 ed. S&atilde;o Paulo: Person Education do Brasil, 2012.</p>
      <p>SHIRAISHI, Guilherme de Farias. <em>Administra&ccedil;&atilde;o de Marketing</em>. S&atilde;o Paulo: Pearson Education do Brasil, 2012.</p>
      <p>BORGES, Leandro. Como desenvolver uma Matriz ou An&aacute;lise SWOT (FOFA). In: Blog Luz VC. Dispon&iacute;vel em: &lt;<a href="https://blog.luz.vc/%3Ci%3Emarketing%3C/i%3E/como-desenvolver-uma-matriz-ou-analise-swot-fofa/">https://blog.luz.vc/marketing/como-desenvolver-uma-matriz-ou-analise-swot-fofa/</a>&gt;. Acesso em 09 jan. 2015.</p>
      <p>THE NEW YORK TIMES. Fabricante japonesa investe em smartphone para a terceira idade. In: Site Medplan. Dispon&iacute;vel em: &lt;<a href="https://www.medplan.com.br/noticias/fabricante-japonesa-investe-em-smartphone-para-a-terceira-idade,25963">https://www.medplan.com.br/noticias/fabricante-japonesa-investe-em-smartphone-para-a-terceira-idade,25963</a>&gt;. Acesso em: 09 de jan. 2015</p>
    `;
  } else if(config.LANGUAGE==='es_es'){
    initialCode = `<div class="E-TOPICO">Introducción</div>
    <p>¡Hola!</p>`;
  }
  return initialCode;
}

export const style = {
  content_style: `
    h1, .e-title, .title-vg {
      font-size: 2em;
      margin: 0.67em 0;
    }
    h2, .e-subtitle, .subtitlei-vg {
      font-size: 1.5em;
      margin: 0.83em 0;
    }
    .subtitleii-vg {
      font-size: 1.25em;
      margin: 0.83em 0;
    }
    .subtitleiii-vg {
      font-size: 1em;
      margin: 0.83em 0;
    }
    body {
      font-family: 'Open Sans', sans-serif;
      color: ${$.colorText($,1)};
      padding: 0 30px;
      max-width: ${$.container};
      margin: 0 auto;
    }
    /* p[class^="I-"] */
    /* div[data-interaction="true"] */
    div[data-interaction="true"] {
      padding: 20px;
      background: ${$.colorB};
      color: white;
      max-width: 50%;
      margin: 10px auto;
      text-align: center;
      border-radius: 5px;
      font-size: 16px;
      position: relative;
    }
    div[data-interaction="true"]:after {
      content: '';
      border: 1px dashed ${lighten(0.1,$.colorB)};
      position: absolute;
      margin: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      top: 0px;
      left: 0px;
      border-radius: 5px;
    }
    div[data-interaction="true"] div {
      display: none;
    }
    .E-TOPICO {
      font-size: 33px;
      font-weight: bold;
      border-bottom: 1px dashed ${$.colorText($,0.5)};
      padding-bottom: 10px;
      /* text-transform: uppercase; */
      margin-top: 50px;
    }
    .quote {
      text-align: justify;
      font-size: 12px;
      padding-left: 30%;
    }
    /* .D-DESTAQUE {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 5px solid ${$.colorB};
    }
    .D-CAIXA {
      margin: 20px;
      padding: 20px;
      border: 5px solid ${$.colorB};
    } */
    a {
      color: ${$.colorB};
    }
    *[data-comments] {
      border-bottom: 1px solid ${$.colorB};
      padding: 5px;
      padding-right: 30px;
      background-color: ${lighten(0.3,$.colorB)};
      background-image: url(${commentIcon});
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 30px;
    }
    .tooltip {
      color: ${$.correct};
      text-decoration: underline;
    }

    /* ===== SOMOS ===== */

    .e-subtitlei-somos {
      font-size: 1.5em;
      margin: 0.83em 0;
    }
    .e-subtitleii-somos {
      font-size: 1.25em;
      margin: 0.83em 0;
    }
    .e-subtitleiii-somos {
      font-size: 1em;
      margin: 0.83em 0;
    }
    .quote.olho {
      font-style: italic;
      text-align: left;
    }
    .quote.fonte {
      ${$.fontSize($,8.5)}
      text-align: right;
    }
    .quote.autor {
      ${$.fontSize($,9.5)}
      text-align: right;
    }
    h5.quote {
      ${$.fontSize($,11)}
      text-transform: uppercase;
      text-align: left;
    }
    .legenda-externa {
      ${$.fontSize($,11)}
    }
    .legenda-interna {
      ${$.fontSize($,11)}
    }
    .gloss_destaque {
      font-weight: bold;
      color: ${$.colorB};
    }
    .texto_vocabulario {
      ${$.fontSize($,9.5)}
      padding: 20px;
      background: #e0ecfd;
      position: relative;
    }
    .texto_vocabulario:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      border:10px solid ${$.white};
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
    .texto_definicao {
      ${$.fontSize($,12)}
      padding: 20px;
      background: #e0ecfd;
    }
    .comunicacao_arte {
      background: cyan;
    }
    .comunicacao_revisao {
      background: yellow;
    }
    .pauta_iconografia {
      color: #1aa51e;
    }
    .pauta_ilustracao {
      color: blue;
    }
    .pauta_mapas {
      color: orange;
    }
  `,
};

export const image = {
  // FONTE
  // https://www.tiny.cloud/docs/general-configuration-guide/upload-images/
  // https://stackoverflow.com/questions/42947331/how-to-encode-image-to-base64-in-tinymce
  images_upload_handler: function (blobInfo, success, failure) {
    /* function SaveImageAsBase64(){
      success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
    } */
    async function LoadImageServer(){
      const data = new FormData();
      var fileName;
      if(typeof(blobInfo.blob().name)!==undefined){
          fileName = blobInfo.blob().name;
      } else {
          fileName = blobInfo.filename();
      }
      data.append('file', blobInfo.blob(), fileName);

      const response = await api.post('files',data);
      // console.log(response.data);
      success(response.data.url);
    }
    LoadImageServer();
    // SaveImageAsBase64();
  },
};

export function formatsGenerator(config,styles){
  if(!styles){
    return {
      // block_formats: 'Paragraph=p; Tópico=h1; Título=h2; Subtítulo=h3;',
      formats: {
        topic: { block: 'div', attributes: { class: 'E-TOPICO'} },
        title: { block: 'h1', attributes: { class: ''} },
        subtitle: { block: 'h2', attributes: { class: ''} },
        paragraph: { block: 'p', attributes: { class: ''} },
        quote: { block: 'p', attributes: {class: 'quote'} },
        // destaque: { block: 'div', attributes: {class: 'D-DESTAQUE'} },
        // caixa: { block: 'div', attributes: {class: 'D-CAIXA'} },
        removeformat: [
          // Configures `clear formatting` to remove the class green from spans and if the element then becomes empty it's left intact
          { selector: 'div', classes: 'none', remove: 'none' }
        ]
      },
      style_formats: [
        // { title: 'Formatação de Texto' },
        { title: config.LANG['Topic'], format: 'topic' },
        { title: config.LANG['Title'], format: 'title' },
        { title: config.LANG['Subtitle Original'], format: 'subtitle' },
        { title: config.LANG['Paragraph'], format: 'paragraph' },
        { title: config.LANG['Quote'], format: 'quote' },
        // { title: 'Destaques' },
        // { title: 'Destaque', format: 'destaque' },
        // { title: 'Caixa', format: 'caixa' },
      ],
    };
  } else {
    const result = handleStyles(config,styles);
    return result.formats;
  }
}

export function handleStyles(config,styles){
  const result = {};
  if(config.LANGUAGE==='pt_br'||styles.lang===undefined){
    result.formats = styles.formats;
    result.content = styles.content;
  } else {
    result.formats = styles.lang.formats[config.LANGUAGE];
    result.content = styles.lang.content[config.LANGUAGE];
  }
  return result;
}

export const plugins = { // https://www.npmjs.com/package/@wiris/mathtype-tinymce5
  // https://github.com/wiris/mathtype-integration-js-dev/blob/master/doc/src/services_tinymce.md
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
    `charmap codesample media emoticons visualblocks tiny_mce_wiris mathtype-editor noneditable`, // class="mceNonEditable"
    'paste', // https://www.tiny.cloud/docs/plugins/paste/#paste_postprocess
  ],
  // PASTE PLUGIN
  paste_auto_cleanup_on_paste : true,
  paste_remove_styles: true,
  paste_remove_styles_if_webkit: true,
  paste_strip_class_attributes: true,
  paste_word_valid_elements: "p,b,strong,i,em,h1,h2,ul,li,ol,table,tr,td,th",
  paste_postprocess: function(plugin, args) {
    // console.log(args.node);
    // args.node.setAttribute('id', '42');
    // 
    // console.log('args.target.selection.getNode()',args);
    var target = args.target.selection.getNode();
    // CANT PASTE INTERACTION INSIDE ANOTHER PLACE
    if(target.nodeName.toLowerCase()!=='p'){
      var elementsNew = args.node.querySelectorAll(':scope > *');
      // args.preventDefault();
      Array.prototype.forEach.call(elementsNew, function(el, i){
        if(el.getAttribute('data-interaction')==='true'){
          args.preventDefault();
        }
      });
    }
    var elements = args.node.querySelectorAll(':scope > *');
    Array.prototype.forEach.call(elements, function(el, i){
      if(el.getAttribute('data-interaction')==='true'){
      } else {
        // console.log(el);
        SanitizeElement(el)
      }
    });
    function SanitizeElement(elx){
      var tag = elx.nodeName.toLowerCase();
      if(tag==='div'){
        // console.log(elx);
        var p = document.createElement('p');
        p.textContent = elx.textContent;
        elx.parentNode.replaceChild(p, elx);
      }
      var attributes = elx.attributes;
      // DELETE CLASS ATTRIBUTE
      if(elx.getAttribute('class')){
        elx.removeAttribute('class');
      }
      // DELETE ALL ATTRIBUTES EXCEPT...
      if(attributes.length!==0){
        for (let index = 0; index < attributes.length; index++) {
          var attribute = attributes[index].name;
          if(attribute!=='href'&&attribute!=='src'&&attribute!=='alt'&&attribute!=='title'){
            elx.removeAttribute(attribute);
          }
        }
      }
      // SANITIZE CHILDS
      var elementsx = elx.querySelectorAll(':scope > *');
      if(elementsx.length>0){
        Array.prototype.forEach.call(elementsx, function(elx, i){
          SanitizeElement(elx);
        });
      }
    }
  }
};

export function menuGenerator(config,profile,template){
  const templates = {
    'master': true,
    'somos': true,
  };
  return {
    // https://www.tiny.cloud/docs/general-configuration-guide/basic-setup/
    menu: { // https://www.tiny.cloud/docs/demo/custom-menu-item/
      tools: { title: 'Tools', items: `${(profile&&profile.role.level<=2)&&'code'} contador` }, // wordcount
      custom: { title: config.LANG['Backup'], items: 'backup' },
      modos: { title: config.LANG['Mode'], items: 'revisor compare' },
      format: { title: config.LANG['Format'], items: `charmap superscript subscript add-mathtype` },
      share: { title: config.LANG['Share'], items: 'share' },
    },
    // menubar: 'file edit view insert format tools table help',
    menubar: `edit tools custom format modos ${(profile&&profile.role.level<=2)&&'share'}`,
    // toolbar:
    //   `undo redo | formatselect | bold italic backcolor |
    //   alignleft aligncenter alignright alignjustify |
    //   bullist numlist outdent indent | removeformat | help |
    //   table fullscreen visualblocks searchreplace image advlist code insertdatetime |
    //   charmap codesample media emoticons myCustomToolbarButton customInsertButton customDateButton doggy`,
    toolbar: // superscript subscript
      `styleselect | 
      bold italic link removeformat backcolor | 
      alignleft aligncenter alignright alignjustify |
      bullist numlist table |
      addcomment comments | interaction${(template&&templates[template.path])?' add-tooltip':''}`,
    contextmenu: "link image imagetools table", // Right click mouse
    statusbar: false, // https://www.tiny.cloud/docs/configure/editor-appearance/
  };
}