import React from 'react';
import {Switch} from 'react-router-dom';
import Route from './Route';

// Rotas de Autenticação
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Password from '../pages/Password';

//Free trial
import FreeTrial from '../pages/Free_trial';

// Compartilhando
import Share from '../pages/Share';

// Pós-autenticação
import Dashboard from '../pages/Dashboard';
import Overview from '../pages/Overview';
import Profile from '../pages/Profile';
import StatesByProject from '../pages/states-by-project';

// Usuários
import Users from '../pages/Users';
import EditUser from '../pages/Users/edit';

// Projetos
import Projects from '../pages/Projects';
import EditProject from '../pages/Projects/edit';

// Áreas
import Areas from '../pages/Areas';
import EditArea from '../pages/Areas/edit';

// Disciplinas
import Subjects from '../pages/Subjects';
import EditSubject from '../pages/Subjects/edit';

// Unidades
import Units from '../pages/Units';
import EditUnit from '../pages/Units/edit';
import Unit from '../pages/Units/view';
import Editor from '../pages/Units/editor';
import Revisor from '../pages/Units/revisor';
import Compare from '../pages/Units/compare';
import Extras from '../pages/Units/extras';

// Configurations
import Configurations from '../pages/Configurations';

// Procedures
import Procedures from '../pages/Procedures';
import EditProcedure from '../pages/Procedures/edit';

// Templates
import Templates from '../pages/Templates';
import EditTemplate from '../pages/Templates/edit';
import { ProjectsExtra } from '~/pages/Projects/projectsExtra';
import { ExtraSubjects } from '~/pages/Subjects/ExtraSubjects';
import { ExtraAreas } from '~/pages/Areas/extraAreas';

export default function Routes(){
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/trial" exact component={FreeTrial} />
      <Route path="/init" component={SignUp} />
      <Route path="/password" component={Password} />

      <Route path="/share/:id" component={Share} isShare isEmpty />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/overview/projects/:id" exact component={Overview} isPrivate />
      <Route path="/projects/:id/procedures" exact component={StatesByProject} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/users" exact component={Users} isPrivate level={2} />
      <Route path="/users/:id" component={EditUser} isPrivate level={2} />

      <Route path="/projects" exact component={Projects} isPrivate />
      <Route path="/projects/:id" exact component={EditProject} isPrivate level={1} />
      <Route path="/projects/:id/extras" exact component={ProjectsExtra} isPrivate level={2} />

      <Route path="/projects/:id/areas" exact component={Areas} isPrivate />
      <Route path="/areas/:id" exact component={EditArea} isPrivate />
      
      <Route path="/areas/:id/extras" exact component={ExtraAreas} isPrivate />
      <Route path="/subjects/:id/extras" exact component={ExtraSubjects} isPrivate />

      <Route path="/areas/:id/subjects" exact component={Subjects} isPrivate />
      <Route path="/subjects/:id" exact component={EditSubject} isPrivate />

      <Route path="/subjects/:id/units" exact component={Units} isPrivate />
      <Route path="/units/:id" exact component={EditUnit} isPrivate />
      <Route path="/units/:id/view" component={Unit} isPrivate />
      <Route path="/units/:id/create" component={Editor} isPrivate isEmpty />
      <Route path="/units/:id/revise" component={Revisor} isPrivate isEmpty />
      <Route path="/units/:id/compare" component={Compare} isPrivate isEmpty />
      <Route path="/units/:id/extras" component={Extras} isPrivate />

      <Route path="/configurations" component={Configurations} isPrivate level={0} />

      <Route path="/procedures" exact component={Procedures} isPrivate level={0} />
      <Route path="/procedures/:id" exact component={EditProcedure} isPrivate level={0} />

      <Route path="/templates" exact component={Templates} isPrivate level={0} />
      <Route path="/templates/:id" exact component={EditTemplate} isPrivate level={0} />

      <Route path="/" component={()=><h1>404</h1>} />
    </Switch>
  );
}