/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import * as GlobalContainerStyle from '../../../components/Container/index'; 
import leftArrow from '~/assets/icons/leftArrow.svg';
import { Form } from '@rocketseat/unform';
import * as InternalForm from '~/components/InternalForm';
import Fields from '~/pages/Units/editor/fields';
import {useParams,useHistory} from 'react-router-dom';
import TopLoader from '~/assets/loading.gif';
import * as Yup from 'yup';
import api from '~/services/api';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as S from '../../../components/Container/index';

// IMPORT
import PopUp from '~/components/PopUp';
import Import from '~/pages/Units/extras/import';
import close from '~/assets/icons/close.svg';

const FIELD_DALEY = 2000;

export const ExtraSubjects = () => {
    const config = useSelector(state => state.config.value);
    const [topload, setTopload] = useState(true);
    const history = useHistory();
    const [fields,setFields] = useState(false);
    const [actual, setActual] = useState('');
    const [initalDatas, setInitalData] = useState('');
    const [extrasId, setExtrasId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [areaId, setAreaId] = useState(0);
    const { id } = useParams();
    const [schema, setSchema] = useState(Yup.object().shape({}));
    const [exist, setExist] = useState(false);
    const [extraSubject, setExtraSubject] = useState('');
    const [areaDefult, setAreaDefault] = useState(false);
    const [projectTemplateDefault, setProjectTemplateDefault] = useState(false);

    const [parent,setParent] = useState({});
    useEffect(()=>{
        async function getInitState(){
            try {
                const responseParent = await api.get(`projects/${projectId}`);
                setParent(responseParent.data);
            } catch(err){
                history.push(`/dashboard`);
            }
        }
        if(projectId) getInitState();
    },[projectId,history]);

    

    // recriando get projectId/areaId

    useEffect(() => {
        async function getProjectId() {
            const {data:{area_id,area:{project_id}}} = await api.get(`subjects/${id}`);
            setAreaId(area_id);
            setProjectId(project_id)
        }
        getProjectId();
    }, [id]);

    //recriando get template default

    useEffect(() => {
        async function getActualTemplate() {
            const {data:{list:templates}} = await api.get('templates',{
                params: {
                    perPage: 1000,
                }
            });
            try {
                const {data:{template}} = await api.get(`rules/single/subjects/${id}`);
                const foundTemplate = templates.find(item => item.id === template.id);
                setActual(foundTemplate);
            } catch(error) {
                try {
                    const {data:{template}} = await api.get(`rules/single/areas/${areaId}`);
                    const foundTemplate = templates.find(item => item.id === template.id);
                    setActual(foundTemplate);
                } catch(error) {
                    console.log(error);
                    try {
                        const {data:{template}} = await api.get(`rules/single/projects/${projectId}`);
                        const foundTemplate = templates.find(item => item.id === template.id);
                        setActual(foundTemplate);
                    } catch(error) {
                        console.log(error);
                        const foundTemplate = templates.find(item => item.default === true);
                        setActual(foundTemplate);
                    }
                }
            }
        }
        if(areaId!==0&&projectId!==0) getActualTemplate();
    }, [id,areaId,projectId]);

    // recriando get template extras

    useEffect(() => {
        async function getTemplateExtras(){
            const {data:{id:extraId,value_json,subject}} = await api.get(`/extras/custom/bylevel`, {
                params: {
                    subject_id: id,
                    template: actual.path,
                }
            });
            setExtraSubject(subject);
            setExtrasId(extraId);
            setInitalData(value_json);
            const {data:{field:fields}} = await api.get(`extras`,{
                params: {
                    template: actual.path,
                }
            });
            setFields(fields);
            const thisSchema = {};
            fields.map(field => {
                thisSchema[field.name] = readSchema(field);
                if(field.type==='multiple'){
                    thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false});
                }
            });
            setSchema(Yup.object().shape(thisSchema));
            setTopload(false);
        }
        if(actual!=='') getTemplateExtras();
    }, [actual, id]);


    /*

    //get template subjects
    useEffect(() => {
        async function init() {
            try {
                const responseIds = await api.get(`subjects/${id}`);
                setAreaId(responseIds.data.area_id);
                setProjectId(responseIds.data.area.project_id);
                const responseA = await api.get(`rules/single/subjects/${id}`);
;               const responseB = await api.get('templates');
                const templatePath = responseB.data.list.find(item => item.id === responseA.data.template.id);
                setActual(templatePath);
            } catch (error) {
                setExist(true);
            }
        }
        init();
    }, [id]);

  //areas
    useEffect(() => {
        if (exist) {
            async function areasDefault() {
                try {
                    const responseA = await api.get(`rules/single/areas/${areaId}`);
                    const responseB = await api.get('templates');
                    const templatePath = responseB.data.list.find(item => item.id === responseA.data.template.id);
                    setActual(templatePath);
                } catch (error) {
                    setAreaDefault(true);
                }
            }
            areasDefault();
        }
    }, [areaId,exist]);

    useEffect(() => {
        if (areaDefult) {
            async function areaDefault() {
                try {
                    const responseA = await api.get(`rules/single/projects/${prjectId}`);
                    const responseB = await api.get('templates');
                    const templatePath = responseB.data.list.find(item => item.id === responseA.data.template.id);
                    setActual(templatePath);
                } catch (error) {
                    setProjectTemplateDefault(true);
                }
            }
            areaDefault();
        }
    }, [prjectId, areaDefult]);

    
    useEffect(() => {
        if (projectTemplateDefault) {
            async function getTemplateDefault() {
                const responseTemplateDefault = await api.get('templates');
                const templatedefault = (responseTemplateDefault.data.list.find(item => item.default === true));
                setActual(templatedefault);
            };
            getTemplateDefault();
        }
    }, [projectTemplateDefault]);

    //pegando o caminho para setFields

    useEffect(() => {

        let template;
        api.get(`/extras/custom/bylevel`, {
            params: {
                subject_id: id,
            }
        }).then(response => {
            setExtraSubject(response.data.subject);
            setExtrasId(response.data.id);
            setInitalData(response.data.value_json);
            template = response.data.template;

        });

        setTimeout(() => {
            
            api.get(`extras`,{
                params: {
                    template: actual.path,
                }
            }).then(response => {
                if (template) {
                    const dataProcess = ProcessData(response.data.field);
                    setInitalData(dataProcess);
                }
                setFields(response.data.field);
                const thisSchema = {};
                response.data.field.map(field => {
                    thisSchema[field.name] = readSchema(field);
                    if(field.type==='multiple'){
                        thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false});
                    }
                    });
                setSchema(Yup.object().shape(thisSchema));
            }); 
        }, 500);

        setTimeout(() => {
            setTopload(false);
        }, FIELD_DALEY)
    }, [actual, id]);

    */

    /* function ProcessData(array){
        var object = {};
        for (let i in array) {
          let item = array[i];
          if(item.type==='multiple'){
            object[item.name] = ProcessData(item.field);
          } else {
            object[item.name] = item.default;
          }
        }
        return object;
      } */
 

    function readSchema(field){
        if(field.type==='multiple'){
          const multipleSchema = {};
          field.field.map(multipleField=>{
            multipleSchema[multipleField.name] = readSchema(multipleField);
            // if(multipleField.type==='multiple'){
            //   multipleSchema[`${multipleField.name}-shadow`] = readSchema({...multipleField,name:`${multipleField.name}-shadow`,field:[{name:'available',required:false}],required:false});
            // }
          });
          if(field.required){
            return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
              Yup.object().shape(multipleSchema)
            ).required(config.LANG['Required field']);
          } else {
            return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
              Yup.object().shape(multipleSchema)
            );
          }
        } else if(field.required){
          return Yup.string().required(config.LANG['Required field']);
        } else {
          return Yup.string();
        }
    }
    
    function handleBack() {
        history.goBack();
    }


    const handleSubmit = useCallback(async (data) => {
        try {
            await api.post(`rules/single/subjects/${id}`, {
                template_id: actual.id
            });
            if (extraSubject === false) {
                await api.post(`/extras/custom/bylevel`, {
                    subject_id: id,
                    template_id: actual.id,
                    value_json: data
                });
            } else {
                await api.put(`extras/${extrasId}`,{
                    value_json: data,
                });
            }
            toast.success('Configurações atualizadas com sucesso');
        } catch (error) {
            toast.error('Ocorreu algum erro ao atualizar os templates, tente novamente');
        }
    }, [actual, id, extraSubject, extrasId]);

    // IMPORT
    const [openImport,setOpenImport] = useState(false);
    function handleOpenImport(){
        setOpenImport(!openImport);
    }
    async function handleAddImport(unit){
        setInitalData(unit.extra.value);
        handleOpenImport();
    }
    
    
    return (
        <>
        {(topload||!parent.name) ?
            (<GlobalContainerStyle.Container>
                <p className="loaderHolder">
                  <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
                </p>
            </GlobalContainerStyle.Container>)
        :
            (<GlobalContainerStyle.Container>
                <S.ButtonBack onClick={handleBack}>
                    <img src={leftArrow} alt={config.LANG['Backward']} />
                    {config.LANG['Subjects']}
                </S.ButtonBack>
                <GlobalContainerStyle.Divider>
                    <GlobalContainerStyle.Title>{config.LANG['Customize by disciplines']}</GlobalContainerStyle.Title>
                    <div>
                        {/* <button type="submit" onClick={handleOpenImport}>{config.LANG['Import']}</button> */}
                    </div>
                </GlobalContainerStyle.Divider>
                <h2>{config.LANG['Current theme options']}</h2>
                <Form schema={schema} initialData={initalDatas} onSubmit={handleSubmit}>
                    <InternalForm.FormRow>
                        {fields && fields.map((field, key) =>
                        <InternalForm.FormColumn key={field.name} Line={(
                            (fields.length===1)
                            ||(fields.length%2!==0&&key===fields.length-1)
                            ||field.type==='file'
                            )||field.full}>
                            <Fields field={field}  />
                        </InternalForm.FormColumn>
                        )}
                    </InternalForm.FormRow>
                    <InternalForm.SubmitRow>
                        <p>*{config.LANG['Required Fields']}</p>
                        <div>
                            <button type="submit">{config.LANG['Update']}</button>
                        </div>
                    </InternalForm.SubmitRow>
                </Form>
                <PopUp Open={openImport} CallBack={handleOpenImport}>
                    <button type="button" onClick={handleOpenImport}>
                    <img src={close} alt={config.LANG['Close']}/>
                    </button>
                    <h1>{config.LANG['Import Customization']}</h1>
                    <br/><br/>
                    <p>{config.LANG['Import Customization from another Unit']}</p>
                    <Import postsId={actual.id} callBack={handleAddImport} />
                    <br/><br/>
                </PopUp>
            </GlobalContainerStyle.Container>
        )}
        </>
    );
}
