import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import {Container,Title} from '~/components/Container';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import ColorPicker from '~/components/ColorPicker';

import leftArrow from '~/assets/icons/leftArrow.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

import Projects from '~/pages/Areas/edit/projects';
import Rules from '~/components/Rules';

export default function EditArea({match:{params:{id:postId}}}) {
  const config = useSelector(state=>state.config.value);
  const [topLoader,setTopLoader] = useState(true);
  const [parentId,setParentId] = useState({});
  const [post,setPost] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  const [parent,setParent] = useState({});
  useEffect(()=>{
    async function getInitState(){
      try {
        const responseParent = await api.get(`projects/${parentId}`);
        setParent(responseParent.data);
      } catch(err){
        history.push(`/dashboard`);
      }
    }
    if(parentId) getInitState();
  },[parentId,history]);
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get(`areas/${postId}`);
      setParentId(response.data.project.id);
      setPost(response.data);
      setTopLoader(false);
    }
    GetInitState();
  }, [postId]);
  
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    try {
      await api.delete(`/areas/${postId}`);
      toast.success(`${config.LANG['Area']} ${config.LANG['FEMALE SINGLE successfully deleted!']}`);
      history.push(`/projects/${parentId}/areas`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  async function handleRecover(){
    try {
      const response = await api.put(`/areas/${postId}`,{
        available: true,
        role_id: post.role_id
      });
      toast.success(`${config.LANG['Area']} ${response.data.name} ${config.LANG['FEMALE SINGLE successfully recovered!']}`);
      history.push(`/projects/${parentId}/areas`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  async function handleSubmit(data){
    try {
      const response = await api.put(`/areas/${postId}`,data);
      const {name} = response.data;
        toast.success(`${config.LANG['Area']} ${name} ${config.LANG['FEMALE successfully edited!']}`);
        history.push(`/projects/${parentId}/areas`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  function updateParent(id){
    setParentId(id);
  }
  // SCHEMAS =====
  const schema = Yup.object().shape({
    name: Yup.string().required(config.LANG['Required field']),
    color: Yup.string().required(config.LANG['Required field'])
  });
  return (
    <>
      {topLoader||!parent.name?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </Container>
      ):(
        <Container className={!post.available&&`unavailable`}>
          <Link to={`/projects/${parentId}/areas`}>
            <img src={leftArrow} alt={config.LANG['Backward']} />
            {config.LANG['Areas']}
          </Link>
          <Title>{config.LANG['Edit']} {config.LANG['Area']}</Title>
          {/* <h2></h2> */}
          <Form schema={schema} initialData={post} onSubmit={handleSubmit}>

            <FormRow>
              <FormColumn>
                <label htmlFor="name">{config.LANG['Name']}:*</label>
                <Input name="name" placeholder={config.LANG['Name']} autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="color">{config.LANG['Color']}:*</label>
                <ColorPicker name="color" />
              </FormColumn>
            </FormRow>
            
            <SubmitRow>
              <p>*{config.LANG['Required Fields']}</p>
              <div>
                {
                  post.available
                    ?(<button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['Delete']}</button>)
                    :(<button className="add" type="button" onClick={handleOpenDelete}>{config.LANG['Recover']}</button>)
                }
                <button type="submit">{config.LANG['Update']}</button>
              </div>
            </SubmitRow>
          </Form>

          {/* <Projects name="area_id" postId={postId} parentId={parentId} callback={updateParent} /> */}

          <Rules post="areas" postId={postId} />

          <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
            <button type="button" onClick={handleOpenDelete}>
              <img src={close} alt={config.LANG['Close']} />
            </button>
            {
              post.available
                ?(
                  <>
                    <h1>{config.LANG['Delete']} {config.LANG['Area']}</h1>
                    <br/><br/>
                    <p>{config.LANG['Are you sure you want to delete']} {config.LANG['FEMALE this']} {config.LANG['Area']}?</p><br/>
                    <p>{config.LANG['FEMALE It will be saved in the trash and you can recover it later.']}</p>
                    <br/><br/>
                  </>
                )
                :(
                  <>
                    <h1>{config.LANG['Recover']} {config.LANG['Area']}</h1>
                    <br/><br/>
                    <p>{config.LANG['Are you sure you want to recover']} {config.LANG['FEMALE this']} {config.LANG['Area']}?</p><br/>
                    <br/><br/>
                  </>
                )
            }
            <FormRow>
              <FormColumn>
                {
                  post.available
                    ?(<button className="add" type="button" onClick={handleDelete}>{config.LANG['YES']}</button>)
                    :(<button className="add" type="button" onClick={handleRecover}>{config.LANG['YES']}</button>)
                }
              </FormColumn>
              <FormColumn>
                <button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['NO']}</button>
              </FormColumn>
            </FormRow>
          </PopUp>
        </Container>
      )}
    </>
  );
}
