import React,{useEffect,useState} from 'react';
import {useSelector} from 'react-redux';

import {FullText} from './styles';

import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';

export default function WordCount({Open,Close,editor,InteractionsData}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  const [wordCount,setWordCount] = useState(0);
  const [wordCountString,setWordCountString] = useState('');
  useEffect(()=>{
    if(Open&&editor&&InteractionsData[0]) countWord();
    function countWord() {
      var contador = editor.getContent();
      var separator = '" data-interaction="true">';
      if(contador.includes(separator)){
        var interactions = contador.split(separator);
        let data = [];
        let j = 0;
        for (let i in interactions) {
          let interaction = interactions[i];
          // NAME =======================================
          if(Number(i)!==interactions.length-1){
            separator = '<div class="';
            if(contador.includes(separator)){
              let name = interaction.split(separator);
              name = name[name.length-1];
              data[j] = {name};
              j++;
            }
          }
          if(Number(i)!==0){
            let jshadow = j-2;
            if(Number(i)===interactions.length-1){
              jshadow = j-1;
            }
            // STRING =======================================
            separator = '</div>\n';
            if(interaction.includes(separator)){
              let string = interaction.split(separator);
              string = `<div class="${data[jshadow].name}" data-interaction="true">${string[0]}</div>\n${string[1]}</div>\n`;
              data[jshadow].string = string;
            }
            // TEXT =======================================
            separator = '<div>{';
            if(interaction.includes(separator)){
              let text = interaction.split(separator)[1];
              separator = '}</div>';
              if(text.includes(separator)){
                text = `{${text.split(separator)[0]}}`;
                let getValues = JSON.parse(text);
                text = '';
                // FIND INTERACTION
                var thisInteraction;
                InteractionsData.map(groupD=>{
                  groupD.interactions.map(interactionD=>{
                    if(data[jshadow].name===interactionD.slug){
                      thisInteraction=interactionD;
                    }
                  });
                });
                text += decodeValuesText(thisInteraction,getValues);
              }
              data[jshadow].text = text;
            }
          }
        }
        // CONSTRUCTOR =======================================
        for (const key in data) {
          contador = contador.split(data[key].string).join(data[key].text);
        }
        // console.log(contador);
      }
      function decodeValuesText(thisInteraction,getValues){
        var text = '';
        for (const key in getValues) {
          // FIND FIELD
          var thisField = thisInteraction.field.filter((item,index)=>key===item.name)[0];
          if(
            thisField.wordCount!==false&&
            thisField.type!=='boolean'&&
            thisField.type!=='file'&&
            !thisField.type.includes('file')
            ){
            if(thisField.type==='multiple'){
              for (const i in getValues[key]) {
                text += decodeValuesText(thisField,getValues[key][i]);
              }
            } else {
              text += ' '+decodeURI(getValues[key]);
            }
          }
        }
        return text;
      }
      var fromString = contador.replace(/<[^>]+>/g, '')
        .split('\n').join(' ')
        .split('&nbsp;').join(' ')
        .split(' ').join(' ')
        .split('  ').join(' ');
      // CHECK LINKS
      var words = fromString.split(' ');
      for (const i in words) {
        if(words[i].includes('www')||words[i].includes('.com')||words[i].includes('http')||words[i].includes('://')){
          words[i] = ' ';
        }
      }
      fromString = words.join(' ');
      fromString = clearSpace(fromString);
      function clearSpace(string){
        if(string.includes('  ')){
          string = string.split('  ').join(' ');
          string = clearSpace(string);
        }
        return string;
      }
      // CHECK LINKS
      var wordCount = countWords(fromString);
      setWordCountString(fromString);
      setWordCount(wordCount);
    }
  },[Open,editor,InteractionsData]);
  return (
    <PopUp Tiny Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h2>{config.LANG['Word Counter']}:</h2>
      <p>{config.LANG['Number of pages']}: <strong>{(parseInt(wordCount/200)<wordCount/200)?parseInt(wordCount/200)+1:wordCount/200}</strong></p><br/>
      <p>{config.LANG['Number of words']}: <strong>{wordCount}</strong></p><br/>
      <p>{config.LANG['Number of characters']}: <strong>{wordCountString.length}</strong></p><br/>
      {(profile&&profile.role.level<=2)&&(
        <>
          <p>{config.LANG['Full text']}:</p>
          <FullText>{renderHTML(wordCountString)}</FullText>
        </>
      )}
    </PopUp>
  );
}

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

function countWords(s){
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').filter(function(str){return str!="";}).length;
  //return s.split(' ').filter(String).length; - this can also be used
}